import { FC } from 'react';
import IconProps from './IconProps';

const ITApplicationIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.57275" y="2" width="9.75" height="9.75" rx="1.1168" stroke="url(#paint0_linear_4183_66129)" strokeWidth="2.2336" />
        <rect x="15.8236" y="2" width="9.75" height="9.75" rx="1.1168" stroke="url(#paint1_linear_4183_66129)" strokeWidth="2.2336" />
        <rect x="1.57312" y="16.25" width="9.75" height="9.75" rx="1.1168" stroke="url(#paint2_linear_4183_66129)" strokeWidth="2.2336" />
        <path
          d="M21.1584 24.8861V24.8926C21.1567 25.1117 21.0689 25.3213 20.9139 25.4763C20.7574 25.6329 20.5451 25.7208 20.3237 25.7208C20.1024 25.7208 19.8901 25.6329 19.7336 25.4763C19.577 25.3198 19.4891 25.1075 19.4891 24.8861V21.8639V21.5847H19.2099H16.1876C15.9662 21.5847 15.7539 21.4967 15.5974 21.3402C15.4409 21.1837 15.3529 20.9714 15.3529 20.75C15.3529 20.5286 15.4409 20.3164 15.5974 20.1598C15.7539 20.0033 15.9662 19.9154 16.1876 19.9154H19.2099H19.4891V19.6362V16.6139C19.4891 16.3925 19.577 16.1802 19.7335 16.0237C19.8901 15.8671 20.1024 15.7792 20.3237 15.7792C20.5451 15.7792 20.7574 15.8671 20.9139 16.0237C21.0704 16.1802 21.1584 16.3925 21.1584 16.6139V19.6361V19.9153H21.4376H24.4599C24.6812 19.9153 24.8935 20.0033 25.0501 20.1598C25.2066 20.3163 25.2945 20.5286 25.2945 20.75C25.2945 20.9714 25.2066 21.1836 25.0501 21.3402C24.8935 21.4967 24.6812 21.5846 24.4599 21.5846H21.4376H21.1584V21.8638V24.8861Z"
          fill="url(#paint3_linear_4183_66129)"
          stroke="url(#paint4_linear_4183_66129)"
          strokeWidth="0.558399"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66129" x1="1.57275" y1="4.9771" x2="11.3228" y2="9.14504" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66129" x1="15.8236" y1="4.9771" x2="25.5736" y2="9.14504" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66129" x1="1.57312" y1="19.2271" x2="11.3231" y2="23.395" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66129" x1="15.0737" y1="18.7061" x2="25.5737" y2="23.1947" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint4_linear_4183_66129" x1="15.0737" y1="18.7061" x2="25.5737" y2="23.1947" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.13086" y="2" width="9.75" height="9.75" rx="1.1168" stroke="currentColor" strokeWidth="2.2336" />
      <rect x="15.3818" y="2" width="9.75" height="9.75" rx="1.1168" stroke="currentColor" strokeWidth="2.2336" />
      <rect x="1.13086" y="16.25" width="9.75" height="9.75" rx="1.1168" stroke="currentColor" strokeWidth="2.2336" />
      <path
        d="M20.7165 24.8861V24.8926C20.7148 25.1117 20.627 25.3213 20.472 25.4763C20.3155 25.6329 20.1032 25.7208 19.8818 25.7208C19.6605 25.7208 19.4482 25.6329 19.2917 25.4763C19.1351 25.3198 19.0472 25.1075 19.0472 24.8861V21.8639V21.5847H18.768H15.7457C15.5243 21.5847 15.312 21.4967 15.1555 21.3402C14.999 21.1837 14.911 20.9714 14.911 20.75C14.911 20.5286 14.999 20.3164 15.1555 20.1598C15.312 20.0033 15.5243 19.9154 15.7457 19.9154H18.768H19.0472V19.6362V16.6139C19.0472 16.3925 19.1351 16.1802 19.2916 16.0237C19.4482 15.8671 19.6605 15.7792 19.8818 15.7792C20.1032 15.7792 20.3155 15.8671 20.472 16.0237C20.6285 16.1802 20.7165 16.3925 20.7165 16.6139V19.6361V19.9153H20.9957H24.018C24.2393 19.9153 24.4516 20.0033 24.6082 20.1598C24.7647 20.3163 24.8526 20.5286 24.8526 20.75C24.8526 20.9714 24.7647 21.1836 24.6082 21.3402C24.4516 21.4967 24.2393 21.5846 24.018 21.5846H20.9957H20.7165V21.8638V24.8861Z"
        fill={accentColor}
        stroke={accentColor}
        strokeWidth="0.558399"
      />
    </svg>
  );
};

export default ITApplicationIcon;
